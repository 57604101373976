<template>
  <Export
      :date_type="null"
      :date_filter="true"
      :entities="entities"
      :status_filter="true"
      :campus_filter="true"
      :campuses="campuses"
      :phase_filter="true"
      :stages_filter="true"
      :homeclasses_filter="true"
      :statuses="statuses"
      :title="'HomeClass Attendances'"
      :year_filter="true"
      :inline_services_option="false"
      :extramurals_filter="true"
  ></Export>
</template>


<script>
import Export from "@/components/exports/Export";
import EnrolmentStatus from "@/models/EnrolmentStatus";
import Campus from "@/models/Campus";

export default {
  name: "homeClassAttendances",
  components: {
    Export,
  },
  data() {
    return {
      entities: "attendances",
      admissions_team: [],

    };
  },
  computed: {
    statuses() {
      return EnrolmentStatus.query().get()
    },
    campuses() {
      return Campus.query().with('phases').get()
    }
  },
  mounted() {
    EnrolmentStatus.FetchAll()
    Campus.FetchAll({page: 1, limit: 99})
  },
};
</script>
